body {
  background-color: rgb(243, 243, 243);
}

.App {
  height: 100vh;
}

#root {
  overflow: hidden;
}

.custom-icon {
  opacity: 0.4;
  margin: 0 2px;
}

.custom-icon-first {
  margin-left: 30px;
}

.custom-icon-selected {
  opacity: 0.1;
  background-color: darkgray;
}

.apexcharts-toolbar {
  max-width: 250px !important;
}

.apexcharts-align-left {
  max-height: none !important;
  margin-left: 15px;
}

.apexcharts-align-left .apexcharts-legend-series {
  width: 100%;
}