  .links {
    margin: 10px 25px !important;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 11px;
  }
  
  .links a {
    color: #777;
  }
  
  .linkActive {
    font-weight: 700;
  }
  